import { Routes, Route } from "react-router-dom";
import CoffreFort from "./Composent/Coffre";
import Footer from "./Composent/Footer";
import Maps from "./Composent/Maps";
import CyberpunkNavbar from "./Composent/NavBar";
import Personnages from "./Composent/Personnages";
import Resume from "./Composent/Resume";
import MusicPage from "./Composent/RIP";



function App() {

  return (
    <>
      <CyberpunkNavbar />
      <Routes>
        <Route path="/" element={<Resume />} />
        <Route path="/personnages" element={<Personnages />} />
        <Route path="/maps" element={<Maps />} />
        <Route path="/enigme" element={<CoffreFort />} />
        <Route path="/RIP" element={<MusicPage />} />
      </Routes>
      <Footer />
   </> 
  );
}

export default App;
