import React, { useState } from "react";
import './CoffreFort.css'
import Coffre from "../../images/coffre.jpg"
import Mechant from "../../images/mechant.gif"

const CoffreFort = () => {
  const [selectedWords, setSelectedWords] = useState(["", "", "", ""]);
  const secretCode = ["Warak", "va", "devenir", "méchant"];
  const isOpen = JSON.stringify(selectedWords) === JSON.stringify(secretCode);

  const words = [
    ["Deba", "Jolyne", "Warak"],
    ["va", "est", "préfère"],
    ["les", "un", "devenir"],
    ["tués", "méchant", "carottes"],
  ];

  const handleSelectChange = (e, index) => {
    const newSelectedWords = [...selectedWords];
    newSelectedWords[index] = e.target.value;
    setSelectedWords(newSelectedWords);
  };

  return (
    <div className="coffre-fort">
      <img src={isOpen ?  Mechant : Coffre} alt={isOpen ?  "Mechant" : "Coffre"} className='porte' />
      <div className="selecteurs">
        {words.map((wordOptions, index) => (
          <select
            key={index}
            value={selectedWords[index]}
            onChange={(e) => handleSelectChange(e, index)}
            
          >
            <option value="" disabled>
              Sélectionnez un mot
            </option>
            {wordOptions.map((word, i) => (
              <option key={i} value={word}>
                {word}
              </option>
            ))}
          </select>
        ))}
      </div>
    </div>
  );
};

export default CoffreFort;
