import React, { useState } from "react";
import Map from "../../images/map.jpg";
import Dome2 from "../../images/mapdome2.jpg";
import Dome4 from "../../images/mapdome4.jpg";
import Dome3 from "../../images/mapdome3.jpg";
import Dome1 from "../../images/mapdome1.jpg";
import Dome5 from "../../images/mapdome5.jpg";
import "./Maps.css";

function Maps() {
  const [currentImage, setCurrentImage] = useState(null);

  const images = {
    dome2: {
      url: Dome2,
      alt: "Dome 2",
    },
    dome4: {
      url: Dome4,
      alt: "Dome 4",
    },
    dome3: {
      url: Dome3,
      alt: "Dome 3",
    },
    dome1: {
      url: Dome1,
      alt: "Dome 1",
    },
    dome5: {
      url: Dome5,
      alt: "Dome 5",
    },
  };

  const handleZoneClick = (imageKey) => {
    setCurrentImage(images[imageKey]);
  };

  const handleContainerClick = (event) => {
    if (event.target === event.currentTarget) {
      setCurrentImage(null);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
      onClick={handleContainerClick}
    >
      <div style={{ position: "relative" }}>
        <img
          src={Map}
          alt="Map"
          style={{ maxWidth: "100%" }}
          onClick={handleContainerClick}
        />
        {currentImage && (
          <div className="overlay-maps" onClick={handleContainerClick}>
            <img
              src={currentImage.url}
              alt={currentImage.alt}
              className="iframe-maps"
            />
          </div>
        )}
        <div
          className="dome"
          onClick={() => handleZoneClick("dome4")}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            borderRadius: "50%",
            transform: "translate(57%, -50%)",
            width: "30%",
            height: "40%",
          }}
        ></div>
        <div
          className="dome"
          onClick={() => handleZoneClick("dome2")}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            borderRadius: "50%",
            transform: "translate(-77%, 61%)",
            width: "15%",
            height: "17%",
          }}
        ></div>
        <div
          className="dome"
          onClick={() => handleZoneClick("dome3")}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            borderRadius: "50%",
            transform: "translate(27%, 92%)",

            width: "22%",
            height: "25%",
          }}
        ></div>
        <div
          className="dome"
          onClick={() => handleZoneClick("dome1")}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            borderRadius: "50%",
            transform: "translate(-9%, -19%)",

            width: "11%",
            height: "11%",
          }}
        ></div>
        <div
          className="dome"
          onClick={() => handleZoneClick("dome5")}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            borderRadius: "50%",
            transform: "translate(93%, 112%)",
            width: "9%",
            height: "9%",
          }}
        ></div>
      </div>
    </div>
  );
}
export default Maps;
