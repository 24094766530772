import React, { useState } from "react";
import "./Personnages.css";
import DEBA from "../../images/DEBA.jpg";
import WARAK from "../../images/WARAK.jpg";
import JOLYNE from "../../images/JOLYNE.jpg";
import CORVIN from "../../images/CORVIN.jpg";


const Personnages = () => {
  const [selectedCategory, setSelectedCategory] = useState("");

  const projects = [
    {
      title: "Deba",
      image: DEBA,
      category: "🪦 Deba Sestar",
    },
    {
      title: "Warrak",
      image: WARAK,
      category: "Warrak Ali",
    },
    {
      title: "Jolyne",
      image: JOLYNE,
      category: "Jolyne Steele",
    },
    {
      title: "Corvin",
      image: CORVIN,
      category: "Corvin Saratt",
    },
  ];

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const uniqueCategories = [
    ...new Set(projects.map((project) => project.category)),
  ];

  const renderCategorySelector = () => {
    return (
      <select
        className="category-selector"
        value={selectedCategory}
        onChange={handleCategoryChange}
      >
        <option value="">↓ Personnages ↓</option>
        {uniqueCategories.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </select>
    );
  };

  const filteredProjects = selectedCategory
    ? projects.filter((project) => project.category === selectedCategory)
    : projects;

  const renderProjects = () => {
    return filteredProjects.map((project, index) => (
      <div key={index} >
       
        <img
          src={project.image}
          alt={`image ${project.title}`}
          className="p-image"
        />
      </div>
    ));
  };

  return (
    <div className="personnages">
      {renderCategorySelector()}
      <div className="r-personnages">{renderProjects()}</div>
    </div>
  );
};

export default Personnages;
