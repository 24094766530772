import React, { useState, useEffect } from "react";
import Piano from "./piano.mp3";
import "./RIP.css";

const MusicPage = () => {
  const [volume, setVolume] = useState(0.02);
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    const audioInstance = new Audio(Piano);
    audioInstance.volume = volume;
    audioInstance.play();
    setAudio(audioInstance);

    return () => {
      audioInstance.pause();
      audioInstance.currentTime = 0;
    };
  }, [volume]);

 

  return (
    <div className="RIP">
      <div className="RIP-container">
        <h1 className="RIP-titre">🕯️ Ils nous ont quittés pendant ce JDR 🕯️</h1>
        <ul className="RIP-liste">
          <li>Deba</li>
          <li>L'enfant tué par Deba</li>
          <li>Des villageois tué par Deba</li>
          <li>
            L'enfant tué par Jolyne <span className="oups"> (ça n'est pas sa faute)</span>
          </li>
          <li>Mylène</li>
          <li>Kostas</li>
          <li>Le Respect</li>
          <li>Des vendeurs de drogue</li>
          <li>Des racistes</li>
          <li>Le mec que Jolyne a défoncé dans les vestières</li>
          <li>Et probablement d'autres qu'on a oublié</li>
        </ul>
      </div>
    </div>
  );
};

export default MusicPage;
