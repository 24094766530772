import React from 'react'
import "./Footer.css"

const Footer = () => {
    const year = new Date().getFullYear();
  return (
    <>
    
    <footer className="dn-footer shapedividers_com-7007">
    <div className="dn-container">
      <p className="dn-copyright">&copy; {year} Dieu Nathan. </p>
      <p style={{fontSize: 9, textAlign: "center"}}>Nathan à le droit à un avantage</p>
      </div>
      </footer>
      </>
  )
}

export default Footer