import React from "react";
import "./CyberpunkNavbar.css";
import { Link } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { FaListAlt } from "react-icons/fa";
import { BsFillGearFill } from "react-icons/bs";
import { GiTombstone } from "react-icons/gi";
import { FaMapSigns } from "react-icons/fa";

const CyberpunkNavbar = () => {
  return (
    <>
      <div className="navbar-wrapper">
        <Link to="/" className="navbar-item">
          <span className="icon">
            <FaListAlt />
          </span>
          <span>Résumé</span>
        </Link>
        <Link to="/personnages" className="navbar-item">
          <span className="icon">
            <FaUserAlt />
          </span>
          <span>Persos</span>
        </Link>
        <Link to="/maps" className="navbar-item">
          <span className="icon">
            <FaMapSigns />
          </span>
          <span>Maps</span>
        </Link>

        <Link to="/enigme" className="navbar-item">
          <span className="icon">
            <BsFillGearFill />
          </span>
          <span>Enigme</span>
        </Link>

        <Link to="/RIP" className="navbar-item">
          <span className="icon">
            <GiTombstone />
          </span>
          <span>RIP</span>
        </Link>
      </div>
      <div className="shapedividers_com-7008"></div>
    </>
  );
};

export default CyberpunkNavbar;
